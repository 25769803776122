<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { keyDown } from "@ui/keyDown";
  import Modal from "./Modal.svelte";

  /*
   * When you set the `errorMsg` property, the dialog will be visible.
   * You need to use the `on:close` event to reset the `errorMsg` to `undefined` in order to hide the dialog
   *
   * TODO - make this more svelte-ishh
   */

  export let errorMsg: string | undefined = undefined;
  export let title: string = "Error";
  export let closeButtonText: string = "Close";

  const dispatcher = createEventDispatcher();
  let modal: HTMLDivElement;
  let button: HTMLButtonElement;

  $: isOpen = errorMsg !== undefined;
  $: if (modal) {
    let modalChildOfBody = document.body.querySelector(".smore-error-message");
    if (modal.parentElement) {
      modal.parentElement.removeChild(modal);
    }
    if (modalChildOfBody) {
      document.body.appendChild(modal);
    }
  }
  $: if (button) {
    window.requestAnimationFrame(() => {
      button.focus();
    });
  }

  function closeDialog() {
    errorMsg = undefined;
    dispatcher("close");
  }
</script>

{#if !import.meta.env.SSR}
  <div use:keyDown={[{ key: "Escape", handler: closeDialog }]} bind:this={modal} class="w-full smore-error-message" class:h-0={!isOpen}>
    <Modal bind:isOpen --popup-width="500px" on:close={closeDialog} {title} let:forceCloseModal>
      <span slot="icon" class="h-5 font-bold text-red-500 material-icons text-18">highlight_off</span>
      <div class="w-full text-14 text-nxgray-500">{@html errorMsg}</div>
      <div slot="footer" class="flex items-center justify-end flex-grow px-4 pt-4 mt-4 -mx-5 border-t">
        <button
          bind:this={button}
          class="px-4 py-1 text-white bg-red-600 rounded outline-none sm-clickable text-15"
          on:click|stopPropagation={forceCloseModal}>{closeButtonText}</button>
      </div>
    </Modal>
  </div>
{/if}

<style>
  button:hover,
  button:focus,
  button:active {
    @apply bg-red-500;
    @apply outline-none;
    @apply ring;
  }
</style>
